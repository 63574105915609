<template>
 <div class="content">
        <div class="info-page">
          <div class="sub-section">
            <div class="container">
              <div class="content-container">
                <div class="content-text text-size-middle">
                  <h1>
                    Заявление о порядке обработки персональных данных компанией «Тева»: Надзор за безопасностью
                    лекарственных препаратов (фармаконадзор и контроль качества)
                  </h1>
                  <h3>
                    Определения, используемые в Заявлении о порядке обработки персональных данных
                  </h3>
                  <p>«Нежелательное явление» (НЯ) – это любое нежелательное, непреднамеренное или неблагоприятное
                    событие, развившееся при применении продукта компании «Тева». В отношении изделий медицинского
                    назначения этот термин также означает «инциденты», а в отношении косметических средств –
                    «серьёзные нежелательные эффекты», но для обеспечения удобства при прочтении этого документа
                    будет использоваться только термин «нежелательное явление».</p>
                  <p>«Аффилированное(-ые) лицо(-а)» – это любое лицо, корпорация, компания, товарищество, совместное
                    предприятие или другая организация, контролирующие, контролируемые или находящиеся под общим
                    контролем компании «Тева». В целях этого документа термин «контроль» означает владение ≥50%
                    обыкновенных голосующих акций или обыкновенных акций, или право назначать ≥50% членов правления
                    соответствующей корпорации, компании, товарищества, совместного предприятия или организации.</p>
                  <p>
                    «Персональные данные» – информация, представленная в любом формате, которая может
                    использоваться, прямо или косвенно, отдельно или совместно с любой другой информацией для
                    идентификации человека.
                  </p>
                  <p>
                    «Тева» используется для обозначения компании «Тева Фармасьютикал Индастриз Лтд.» (Teva
                    Pharmaceutical Industries Ltd.) с главным офисом, расположенным по адресу: 5 Базель Стрит,
                    Петах-Тиква, 49131, Израиль, и/или её Аффилированных лиц, также обозначаемых в этом документе
                    как «мы», «нас» и «наш».
                  </p>
                  <h3>
                    Компания «Тева» и конфиденциальность персональных данных
                  </h3>
                  <p>
                    Обеспечение безопасности пациентов является чрезвычайно важной задачей для компании «Тева», и мы
                    со всей серьёзностью относимся к безопасности применения всех наших продуктов. Компании «Тева»
                    необходимо иметь возможность связываться с людьми, которые обращаются в компанию по поводу её
                    продуктов, для обеспечения соответствующего наблюдения и получения дополнительной информации,
                    предоставления ответов на запросы или отправки запрашиваемых материалов. В этом документе
                    описывается порядок сбора и использования персональных данных, для того чтобы мы могли выполнять
                    наши обязательства по контролю за безопасностью всех наших продуктов, включая зарегистрированные
                    или разрабатываемые лекарственные средства (также известные как «обязательства по
                    фармаконадзору»), и обеспечивать качество и безопасность всех наших продуктов.
                  </p>
                  <p>Данный документ также применим к косметическим средствам, биологически активным добавкам и
                    изделиям медицинского назначения, поскольку международные (в том числе европейские) нормативные
                    требования в отношении таких продуктов требуют аналогичного мониторинга безопасности и контроля
                    качества. Однако для обеспечения удобства при прочтении этого документа в нём используется
                    только текст касательно обеспечения безопасности лекарственных средств.</p>
                  <h3>
                    Область действия данного Заявления о порядке обработки персональных данных
                  </h3>
                  <p>
                    Данное Заявление о порядке обработки персональных данных применимо к информации, которую мы
                    получаем
                    от Вас или собираем о Вас через интернет, по телефону, факсу, электронной почте или обычной
                    почте, или в рамках деятельности по сообщению о нежелательных явлениях или контролю качества,
                    применимой к компании «Тева». Кроме того, мы можем собирать эту информацию о Вас путём
                    использования специальных форм, отправляемых Вами через сайт, который принадлежит компании
                    «Тева» или контролируется ею.
                  </p>
                  <p>
                    Если Вы являетесь пациентом, нам также может быть предоставлена информация о Вас от третьих лиц,
                    сообщающих
                    о развившемся у Вас нежелательном явлении. Такими третьими лицами могут являться медицинские
                    специалисты, юристы, родственники или другие представители общественности.
                  </p>
                  <h3>
                    Собираемая информация и цели её сбора
                  </h3>
                  <p>
                    Компания «Тева» имеет юридическое обязательство собирать специфичные данные в интересах
                    общественности в области общественного здравоохранения (Статья 9.2 (i) Общего регламента ЕС по
                    защите персональных данных). Согласно законодательству, фармацевтические компании, являющиеся
                    держателями регистрационных удостоверений на продукты, должны хранить всю документацию,
                    связанную с соответствующим продуктом, как минимум на протяжении срока действия регистрационного
                    удостоверения и ещё в течение 10 лет после его истечения. Следовательно, персональная
                    информация, имеющая отношение к безопасности наших продуктов, будет храниться в течение
                    указанного периода.
                  </p>
                  <h3>
                    Пациенты (субъекты сообщения)
                  </h3>
                  <p>
                    Сбор персональных данных о Вас происходит при предоставлении нам Вами или третьими лицами
                    информации касательно нежелательного явления, развившегося у Вас или другого человека. Если
                    автором сообщения о нежелательном явлении являетесь Вы сами, то см. раздел «Лица,
                    предоставляющие сообщение».
                  </p>
                  <p>
                    Законы, регламентирующие деятельность по фармаконадзору, требуют от нас регистрацию подробных
                    данных о каждом нежелательном явлении, о котором нам становится известно, для того чтобы
                    обеспечить его оценку и сопоставление с другими нежелательными явлениями, зарегистрированными
                    при применении соответствующего продукта. Персональные данные, которые мы можем собирать о Вас в
                    случаях, когда Вы являетесь субъектом сообщения о нежелательном явлении, включают в себя
                    следующие:
                  </p>
                  <p>
                    · ФИО или инициалы; <br>
                    · возраст и дата рождения;<br>
                    · пол;<br>
                    · масса тела и рост;<br>
                    · информация о продукте, вызвавшем нежелательную реакцию, включая принятую или назначенную дозу
                    препарата,
                    причину, по которой применялся или был назначен продукт, и любые последующие изменения в режиме
                    применения
                    продукта;<br>
                    · информация о других препаратах или средствах, применяемых в настоящее время или применявшихся
                    на момент
                    развития реакции, включая принятую или назначенную дозу препарата, длительность применения,
                    причину, по которой применялся препарат, и любые последующие изменения в режиме применения;<br>
                    · информация о развившейся нежелательной реакции, о получаемом лечении в связи с развившейся
                    реакцией, и о любых отдалённых последствиях этой реакции для Вашего здоровья; · другие данные из
                    медицинского анамнеза, которые будут расценены как значимые лицом, составляющим отчёт,
                    включая лабораторные отчёты, лекарственный анамнез и анамнез заболевания.<br>
                  </p>
                  <p>
                    Согласно законодательству часть этой информации о Вас считается «конфиденциальными персональными
                    данными». Они включают в себя любую информацию о Ваших:
                  </p>
                  <p>
                    · здоровье; <br>
                    · этнической принадлежности;<br>
                    · религии;<br>
                    · половой жизни<br>
                  </p>
                  <p>
                    Эта информация обрабатывается только в тех случаях, когда это требуется и необходимо для
                    надлежащего документирования развившейся у Вас реакции и в целях соблюдения требований по
                    фармаконадзору, обеспечению безопасности и любых других законных требований. Эти требования
                    введены для того, чтобы мы и уполномоченные органы (такие как Европейское агентство по
                    лекарственным средствам [EMA]) имели возможность оценивать нежелательные явления и принимать
                    меры для профилактики подобных явлений в будущем.
                  </p>
                  <h3>
                    Лица, предоставляющие сообщение
                  </h3>
                  <p>
                    Сбор информации о Вас происходит при предоставлении нам Вами информации касательно сообщаемых
                    Вами нежелательных явлений.
                  </p>
                  <p>
                    Законы, регламентирующие деятельность по фармаконадзору, требуют от нас обеспечения
                    отслеживаемости нежелательных явлений и их доступности для дальнейшего наблюдения. В результате
                    мы должны предоставить достаточную информацию о лицах, предоставляющих сообщение о НЯ, для того
                    чтобы с ними можно было связаться после получения сообщения. Персональные данные, которые мы
                    можем собирать о Вас в случаях, когда Вы сообщаете о НЯ, включают Ваши:
                  </p>
                  <p>
                    · ФИО; <br>
                    · контактные данные (которые могут включать Ваш адрес, адрес электронной почты, телефон или
                    факс);<br>
                    · данные о профессии (эта информация может использоваться при формулировке вопросов, которые Вам
                    могут
                    задавать о нежелательном явлении, в зависимости от Вашего предполагаемого уровня медицинских
                    знаний);<br>
                    · взаимоотношения с субъектом сообщения.<br>
                  </p>
                  <p>
                    Если Вы также являетесь субъектом сообщения, эта информация может быть объединена с информацией,
                    которую Вы предоставляете в связи с развившейся у Вас реакцией.
                  </p>
                  <h3>
                    Использование и передача персональных данных
                  </h3>
                  <p>
                    В рамках выполнения наших обязательств по фармаконадзору мы можем использовать и передавать
                    персональные данные в целях:
                  </p>
                  <p>
                    · изучения нежелательных явлений; <br>
                    · осуществления контактов с Вами для получения дополнительной информации о сообщаемом Вами
                    нежелательном
                    явлении;<br>
                    · сопоставления информации о нежелательном явлении с информацией о других нежелательных
                    явлениях, полученной
                    компанией «Тева», с целью анализа безопасности конкретной партии, продукта компании «Тева» или
                    активного
                    вещества препарата в целом;<br>
                    · предоставления обязательных отчётов в национальные и/или региональные уполномоченные органы,
                    для того чтобы
                    они могли провести анализ безопасности конкретной партии, продукта компании «Тева» или активного
                    вещества
                    препарата в целом наряду с отчётами из других источников.<br>
                  </p>
                  <p>
                    Персональные данные, полученные от Вас в порядке, описанном в данном документе, также могут
                    передаваться третьим сторонам в случае продажи, предоставления, передачи или приобретения
                    компании или конкретного продукта/разработок в терапевтической области; в таком случае мы будем
                    требовать от покупателя, принимающей стороны или приобретателя соблюдения требований применимых
                    законов о защите персональных данных.
                  </p>
                  <p>
                    В соответствии с законами, регламентирующими деятельность по фармаконадзору, мы передаём
                    информацию национальным и/или региональным уполномоченным органам, таким как Европейское
                    агентство по лекарственным средствам. Мы не можем контролировать использование ими любой
                    передаваемой нами информации, однако следует принять к сведению, что в этих обстоятельствах мы
                    не сообщаем никакую информацию, с помощью которой можно идентифицировать личность человека
                    (например, ФИО или контактные данные), а передаём только обезличенную информацию.
                  </p>
                  <p>
                    Мы можем опубликовать информацию о нежелательных явлениях (например, в рамках анализа конкретных
                    случаев или в соответствующих резюме); в этом случае мы удалим любую идентифицирующую информацию
                    из публикаций, чтобы было невозможно идентифицировать ничью личность.
                  </p>
                  <h3>
                    Международная база данных
                  </h3>
                  <p>
                    Наши обязательства по фармаконадзору требуют от нас системного анализа отчётов, получаемых из
                    каждой страны, в которой зарегистрированы наши продукты. Для обеспечения соответствия этим
                    требованиям информация, предоставляемая в рамках сообщения о нежелательных явлениях,
                    распространяется по всем международным отделениям компании «Тева» посредством Международной базы
                    данных компании «Тева». Эта база данных также является платформой, посредством которой компания
                    «Тева» направляет отчёты о НЯ в различные контролирующие органы, включая базу данных
                    Eudravigilance (корпоративная система Европейского агентства по лекарственным средствам для
                    управления и анализа информации о подозреваемых нежелательных реакциях, развивающихся при
                    использовании лекарственных препаратов, которые разрешены к применению на территории Европейской
                    экономической зоны), и другие аналогичные базы данных, согласно требованиям законодательства.
                  </p>
                  <h3>
                    Ваши права
                  </h3>
                  <p>
                    Поскольку безопасность пациентов является чрезвычайно важной задачей, мы сохраняем всю
                    информацию, собираемую о Вас в рамках получения отчётов о НЯ, чтобы иметь возможность оценивать
                    долгосрочную безопасность наших продуктов.
                  </p>
                  <p>
                    Для Европы: в соответствии с действующим законодательством Вы имеете право запрашивать у
                    компании «Тева» копию собранной о Вас информации, внесение в неё исправлений, её удаление или
                    ограничение обработки, либо делать нам запрос на передачу некоторой из этой информации другим
                    организациям. Кроме того, Вы имеете право на возражение против некоторых вариантов обработки
                    информации. В некоторых ситуациях эти права могут быть ограничены – например, в случаях, когда
                    мы можем подтвердить законные требования о необходимости обработки или хранения Ваших
                    персональных данных. Вы можете воспользоваться перечисленными правами, связавшись с
                    уполномоченным сотрудником компании «Тева», ответственным за обеспечение защиты персональных
                    данных в Европе, по адресу: <a href="mailto:EUPrivacy@tevaeu.com">EUPrivacy@tevaeu.com.</a>
                  </p>
                  <p>
                    Обратите внимание, что по причинам правового характера мы не можем удалить информацию, собранную
                    в рамках сообщения о нежелательных явлениях, за исключением случаев, если она является неточной.
                    Кроме того, мы можем потребовать от Вас подтверждения Вашей личности, прежде чем выполним
                    какой-либо запрос по поводу доступа к персональным данным или их исправления.
                  </p>
                  <p>
                    Мы надеемся, что сможем разрешить любые запросы, которые Вы можете сделать относительно порядка
                    обработки Ваших персональных данных. Если у Вас появятся какие-либо вопросы/сомнения касательно
                    обработки нами Ваших персональных данных, Вы можете связаться с Отделом защиты данных компании
                    «Тева» - адрес для Европы: <a href="mailto:EUPrivacy@tevaeu.com">EUPrivacy@tevaeu.com</a> (адрес
                    для Германии: <a href="mailto:datenschutz@teva.de">datenschutz@teva.de</a>). Если
                    у Вас имеются неразрешённые проблемы, то Вы также можете подать жалобу в уполномоченный орган по
                    надзору за соблюдением законодательства о защите персональных данных в Вашей местности. По этой
                    ссылке представлена контактная информация соответствующих уполномоченных органов в странах ЕС.
                    Для получения информации по другим регионам, пожалуйста, свяжитесь с нами по адресу:
                    <a href="mailto:IL_Privacy.Tevail@teva.co.il">IL_Privacy.Tevail@teva.co.il</a>.
                  </p>
                  <h3>Безопасность</h3>
                  <p>Компания «Тева» принимает меры по обеспечению безопасности персональных данных от случайной
                    потери и несанкционированного доступа, использования, изменения или раскрытия. Кроме того, мы
                    принимаем дополнительные меры по обеспечению безопасности информации, включая использование
                    средств контроля доступа, строгих требований по обеспечению физической безопасности и надёжных
                    методов сбора, хранения и обработки информации.</p>
                  <h3>
                    Международная передача данных
                  </h3>
                  <p>
                    Все базы данных системы фармаконадзора, используемые компанией «Тева», включая Международную
                    базу данных, расположены в Израиле. Управление базами данных и их поддержка осуществляются
                    круглосуточно командами IT-специалистов системы фармаконадзора компании «Тева» в Израиле,
                    Румынии, Германии и США. В Индии компания «Тева» также пользуется услугами компании по обработке
                    данных (компания Accenture) для ввода данных, управления данными и очистки данных в ограниченной
                    части базы данных системы фармаконадзора. Передача данных в Израиль осуществляется на основании
                    решения Европейской комиссии о достаточности мер для государства Израиль. Передача данных в США
                    осуществляется на основании сертификации, полученной компанией «Тева» в рамках программы по
                    защите конфиденциальных данных в США. Передача данных в Индию осуществляется на основании
                    типовых положений Европейской комиссии. Для получения дополнительной информации о любом из этих
                    вариантов передачи информации, пожалуйста, свяжитесь с нами по адресу электронной почты,
                    указанному ниже.
                  </p>
                  <p>
                    Информация о пациентах также может передаваться по всему миру в пределах нашей Международной
                    базы данных. Такая передача данных может включать в себя передачу за пределы Вашей страны в
                    государства, в которых имеются различия в законах о защите данных. Компания «Тева» предпринимает
                    соответствующие меры для обеспечения надлежащей защиты персональных данных при их передаче в
                    такие страны. При нахождении данных в базах данных компании «Тева» к ним применяются все меры по
                    обеспечению безопасности, описанные в этом документе, а когда данные подвергаются обработке в
                    системах третьих сторон, компания «Тева» заключает с такими сторонами соглашения, которые
                    гарантируют соблюдение третьими сторонами соответствующих требований и адекватных мер по
                    обеспечению безопасности.
                  </p>
                  <h3>
                    Внесение изменений в Заявление о порядке обработки персональных данных
                  </h3>
                  <p>Если мы решим внести какие-либо изменения в это Заявление о порядке обработки персональных
                    данных, то мы опубликуем информацию о таких изменениях на сайте с помощью визуально
                    выделяющегося уведомления.</p>
                  <h3>
                    Контактная информация
                  </h3>
                  <p>
                    Персональные данные передаются в компанию «Тева», размещаются и хранятся в базах данных на
                    серверах, расположенных в Израиле, которые принадлежат и обслуживаются компанией Teva
                    Pharmaceutical Industries Ltd. – израильской компанией с ограниченной ответственностью,
                    осуществляющей основную деятельность по адресу:
                    5 Базель Стрит, а/я 3190, Петах-Тиква, 49131, Израиль.
                  </p>
                  <p>
                    Если в любой момент у Вас возникнут вопросы или проблемы, связанные с данным Заявлением о
                    порядке обработки персональных данных, свяжитесь по электронной почте с нашим уполномоченным
                    сотрудником, ответственным за обеспечение защиты персональных данных в Европе, по адресу:
                    <a href="mailto:EUPrivacy@tevaeu.com">EUPrivacy@tevaeu.com</a>. Для получения информации в других регионах, пожалуйста, свяжитесь с нами
                    по адресу: <a href="mailto:IL_Privacy.Tevail@teva.co.il">IL_Privacy.Tevail@teva.co.il</a>. Мы будем прилагать все разумные усилия для
                    предоставления своевременных ответов на Ваши вопросы или решения возникшей у Вас проблемы.
                  </p>
                  <p>Дата вступления в силу: Октябрь 2019 г.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
</template>

<script>
export default {
  name: 'statement',
  title: 'Заявление о порядке обработки персональных данных компанией «Тева»: Надзор за безопасностью лекарственных препаратов (фармаконадзор и контроль качества)'
}
</script>

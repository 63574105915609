<template>
  <div class="content">
    <div class="education-block">
      <div class="container">
        <div class="education-content">
          <div class="desc">
            <div class="text">Конференции, вебинары, лекции, мастер-классы</div>
          </div>
          <div class="pic" style="background-image: url(img/pic9.png)"></div>
        </div>
      </div>
    </div>

    <div class="container inside-page content-section">
      <div class="webinars-forms">
        <div class="search-form">
          <input
            type="text"
            placeholder="Поиск по разделу"
            v-model="searchQuery.searchString"
            @keyup.enter="searchTraining"
          />
          <button @click="searchTraining"></button>
        </div>
        <div class="webinar-select">
          <select v-model="searchQuery.trainingType">
            <option
              v-for="option in availableTrainingType"
              v-bind:value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="webinars-list">
        <div class="row">
          <div
            class="col-md-6"
            v-for="training in trainings.data"
            v-bind:key="training.id"
          >
            <router-link
              :to="'/training/' + training.id"
              class="event-info-block"
            >
              <div
                class="pic"
                :style="{ backgroundImage: `url('${training.preview_image}')` }"
              ></div>
              <div class="desc type1">
                <div class="theme">{{ training.type }}</div>
                <div class="text">{{ training.title }}</div>
                <div class="text-inform">
                  {{ training.description_preview }}
                </div>
                <div class="date">{{ getStringDate(training.date) }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <ul class="pagination-block">
        <li
          class="prev"
          v-bind:class="{ disabled: trainings.meta.current_page === 1 }"
        >
          <a @click="goToPage(trainings.meta.current_page - 1)"></a>
        </li>

        <li
          v-bind:class="{ active: i === trainings.meta.current_page }"
          v-for="i in trainings.meta.last_page"
          v-bind:key="i"
          style="cursor: pointer"
        >
          <a @click="goToPage(i)">{{ i }}</a>
        </li>

        <li
          class="next"
          v-bind:class="{
            disabled: trainings.meta.current_page === trainings.meta.last_page,
          }"
        >
          <a @click="goToPage(trainings.meta.current_page + 1)"></a>
        </li>
      </ul>
    </div>

    <div class="container content-section">
      <a
        href="https://neuro-lessons.kz/"
        target="_blank"
        class="sertificate-lesson aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
      >
        <div class="desc">
          <div class="title">
            Сертифицированный цикл дистанционного <br />
            повышения квалификации
          </div>
          <div class="link">neuro-lessons.kz</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'training',
  metaInfo: {
    title: 'Обучение',
    meta: [
      {
        name: 'description',
        content:
          'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность'
      }
    ]
  },
  data () {
    return {
      searchQuery: {
        searchString: '',
        trainingType: '',
        page: 1
      }
    }
  },

  computed: {
    availableTrainingType () {
      return [
        { id: '', name: 'Все вебинары' },
        { id: 'conference', name: 'Конференция' },
        { id: 'lecture', name: 'Лекция' },
        { id: 'master-class', name: 'Мастер-класс' },
        { id: 'webinar', name: 'Вебинар' }
      ]
    },
    trainings () {
      return this.$store.getters.getTrainings
    }
  },

  async mounted () {
    await this.searchTraining()
  },

  methods: {
    async searchTraining () {
      this.loading = true
      await this.$store
        .dispatch('fetchTrainings', this.searchQuery)
        .then(({ data }) => {
          this.loading = false
          console.log('ska', data)
        })
        .catch(() => {
          this.loading = false
        })
    },

    getStringDate (date) {
      const momentDate = moment(date)
      return momentDate.locale('ru').format('DD MMMM YYYY')
    },

    trainingImageStyle (element) {
      const imageSRC = element.preview_image
      return {
        'background-image': 'url(' + imageSRC + ')'
      }
    },

    async goToPage (page) {
      this.searchQuery.page = page
      await this.searchTraining()
      console.log('hoa')
    }
  }
}
</script>

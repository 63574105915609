<template>
   <div class="content">

        <div class="info-page">
          <div class="sub-section">
            <div class="container">
              <div class="content-container">

                <div class="content-text text-size-middle">
                  <h1>
                    Уведомление о конфиденциальности
                    и cookie-файлах
                  </h1>
                  <h3>
                    Термины и определения
                  </h3>
                  <p>«Уведомление» – настоящее Уведомление о конфиденциальности и cookie-файлах.</p>
                  <p>«Аффилированное(-ые) лицо(-а)» – это любое лицо, корпорация, компания, товарищество, совместное
                    предприятие или другая организация, контролирующие, контролируемые или находящиеся под общим
                    контролем Тева.</p>
                  <p>
                    В целях этого документа термин «контроль» означает владение ≥50% обыкновенных голосующих акций
                    или обыкновенных акций, или право назначать ≥50% членов правления соответствующей корпорации,
                    компании, товарищества, совместного предприятия или организации.</p>
                  <p>
                    «Персональные данные» – информация, представленная в любом формате, которая может
                    использоваться, прямо или косвенно, отдельно или совместно с любой другой информацией для
                    идентификации человека.
                  </p>
                  <p>
                    «Пользователь» – любой посетитель Сайта, любое лицо, осуществляющее ознакомление с информацией,
                    содержащейся на Сайте, или претендующее на доступ к Сайту или такой информации.
                  </p>
                  <p>
                    «Тева» используется для обозначения компании «Тева Фармасьютикал Индастриз Лтд.» (Teva
                    Pharmaceutical Industries Ltd.) с главным офисом, расположенным по адресу: 5 Базель Стрит,
                    Петах-Тиква, 49131, Израиль, и/или её Аффилированных лиц, включая Компанию, также обозначаемых в
                    этом документе как «мы», «нас» и «наш».
                  </p>
                  <p>«Компания» – Товарищество с ограниченной ответственностью «ратиофарм Казахстан» (БИН 071 040 003
                    425).</p>
                  <h3>
                    Администрирование Сайта
                  </h3>
                  <p>Администрирование Сайта осуществляется Компанией совместно с Тева и Аффилированными лицами. Вы
                    можете просмотреть список всех компаний, являющихся Аффилированными лицами, на следующем
                    интернет-ресурсе: <a href="https://www.tevapharm.com"></a> www.tevapharm.com.</p>
                  <h3>Сфера действия Уведомления</h3>
                  <p>Данное Уведомление применяется к Персональным данным, которые мы собираем посредством Сайта.
                    В нем описывается то, как мы используем ваши Персональные данные, и ваши права по защите
                    Персональных данных, в том числе право ограничивать некоторые виды обработки Персональных
                    данных, которую мы осуществляем. Дополнительная информация о ваших правах и порядке их
                    осуществления приведена в разделе «Как мы храним вашу информацию и ваши права».</p>
                  <p>
                    Наш Сайт может содержать ссылки на другие интернет-ресурсы, которые мы не контролируем. Тева не
                    несет никакой ответственности за подходы к конфиденциальности и способы ее обеспечения на таких
                    интернет-ресурсах. Кроме того, ссылки на нашем Сайте могут направлять Посетителей на другие
                    администрируемые Тева интернет-ресурсы, которые применяют свои собственные политики
                    конфиденциальности.
                  </p>
                  <h3>
                    Как мы используем вашу информацию
                  </h3>
                  <p>
                    Мы обрабатываем Персональные данные, с вашего согласия, для следующих целей: <br>
                    · предоставления вам информации о коммерческой деятельности, товарах или услугах;<br>
                    · ответа на ваши запросы или другую корреспонденцию, которую вы направили посредством Сайта;<br>
                    · размещения cookie-файлов и других технологий, как описано ниже;<br>
                    · обеспечения возможности реализации наших деловых интересов, в частности:<br>
                    · анализа использования Сайта, для того чтобы непрерывно улучшать контент и измерять
                    эффективность;<br>
                    · оптимизации и адаптации использования Сайта и нашего общения с вами;<br>
                    · соблюдения законодательных нормативных требований, а также требований фармакологического
                    надзора<br>
                    · и соответствия, для того чтобы отвечать на запросы государственных органов о предоставлении
                    информации.<br>
                  </p>
                  <h3>Использование ваших Персональных данных</h3>
                  <p>
                    Тева может разглашать ваши Персональные данные следующим категориям получателей
                    (во всех случаях только при необходимости выполнения их обязанностей):<br>
                    · нашему персоналу (в том числе работникам и внешним консультантам), профессиональным
                    консультантам и агентам;<br>
                    · функциональным подразделениям и компаниям, являющимся Аффилированными лицами, во всем
                    мире;<br>
                    · сторонним поставщикам услуг, которые обрабатывают ваши Персональные данные от имени Тева и
                    которые связаны договорными обязательствами по обеспечению конфиденциальности и надлежащей
                    защиты ваших Персональных данных;<br>
                    · покупателям или потенциальным покупателям всего бизнеса Тева или его части (и их
                    профессиональным консультантам);<br>
                    · государственным органам и организациям, в том числе сотрудникам правоохранительных органов,
                    если это требуется для целей, указанных выше, если это предусмотрено законодательством либо если
                    это необходимо для юридической защиты наших законных интересов в соответствии с применимым
                    законодательством.<br>
                  </p>
                  <h2>Cookie-файлы и похожие технологии отслеживания</h2>
                  <h3>Использование cookie-файлов на Сайте</h3>
                  <p>
                    Тева использует cookie-файлы для сбора информации о вас и хранения ваших сетевых предпочтений.
                    Cookie-файлы содержат небольшие фрагменты информации, которая загружается на ваше устройство при
                    посещении Сайта.</p>
                  <p>
                    Затем cookie-файлы отправляются обратно на Сайт, когда вы туда возвращаетесь. Это позволяет
                    Сайту распознать ваше устройство. Более подробные сведения о cookie-файлах смотрите на
                    интернет-ресурсе <a href="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org.</a>
                  </p>
                  <h3>Тева использует на Сайте следующие категории cookie-файлов.</h3>
                  <p>
                    Категория 1: строго необходимые cookie-файлы. Эти cookie-файлы необходимы, чтобы позволять вам
                    беспрепятственно перемещаться по Сайту и использовать его функции. Без этих cookie-файлов
                    запрошенные вами услуги (например, запоминание ваших учетных данных) не предоставляются.
                  </p>
                  <p>
                    Категория 2: эксплуатационные cookie-файлы. Эти cookie-файлы собирают информацию о том, как
                    Посетители используют Сайт. Например, Тева использует эти cookie-файлы, чтобы понять, каким
                    образом Посетители попадают на Сайт, просматривают или используют Сайт. Все это позволяет нам
                    понять элементы, которые необходимо улучшить, например, навигацию, возможности взаимодействия с
                    Пользователями и маркетинговые кампании. Вся информация, получаемая посредством этих
                    cookie-файлов, собирается в единое целое и является анонимной. Эта информация используется
                    только для улучшения работы Сайта.
                  </p>
                  <p>
                    Категория 3: функциональные cookie-файлы. Эти cookie-файлы запоминают сделанный вами выбор
                    (например, выбор языка). Собранная таким образом информация позволяет сделать ваши следующие
                    посещения Сайта более индивидуализированными. Информация, собираемая этими cookie-файлами, может
                    быть обезличена и эти cookie-файлы не могут отслеживать ваши действия на других
                    интернет-ресурсах.
                  </p>
                  <p>
                    Если вы желаете удалить какие-либо cookie-файлы, уже хранящиеся на вашем компьютере, обратитесь
                    к разделу помощи и поддержки вашего интернет-браузера за инструкциями по поиску файла или
                    каталога, содержащего cookie-файлы.
                  </p>
                  <p>
                    Сведения по удалению или управлению cookie-файлами также доступны на интернет-ресурсе
                    <a href="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org.</a> Обратите
                    внимание, что после удаления наших cookie-файлов (или
                    отключения будущих cookie-файлов) ваш доступ к определенным разделам или функциям Сайта может
                    стать невозможным.
                  </p>
                  <p>Использование веб-маяков</p>
                  <p>
                    Некоторые страницы Сайта и отправляемые нами сообщения электронной почты могут содержать
                    электронные изображения, известные как веб-маяки (иногда называемые пустыми gif-файлами),
                    позволяющие нам вести учет Пользователей, которые посетили эти страницы или прочли наши
                    сообщения электронной почты. Веб-маяки собирают только ограниченную информацию, к которой
                    относятся номер cookie-файла, время и дата просмотра страницы и описание страницы, на которой
                    находится веб-маяк. Эти маяки не переносят Персональных данных, а используются только для
                    проверки эффективности отдельной кампании.
                  </p>
                  <p>Способы хранения вашей информации и ваши права</p>
                  <p>Мы храним всю информацию, которую собираем о ваших взаимодействиях с Тева. Ваша информация будет
                    храниться до минования надобности в ней либо в течение срока, разумно необходимого для целей,
                    указанных выше,
                    в соответствии с применимым законодательством.</p>
                  <p>
                    Вы вправе, согласно применимому законодательству, запросить у Тева копию ваших Персональных
                    данных, чтобы их исправить, удалить, ограничить их обработку или попросить нас передать часть
                    этой информации другим организациям. Эти права могут быть ограничены в порядке и случаях,
                    установленных применимым законодательством.
                  </p>
                  <p>
                    Предоставленное вами согласие на сбор и обработку ваших Персональных данных может быть отозвано
                    в любое время с учетом требований применимого законодательства.
                  </p>
                  <p>
                    Для осуществления какого-либо из ваших прав в отношении ваших Персональных данных, пожалуйста,
                    свяжитесь с нами. Если вы живете в Европе, свяжитесь с нами по адресу <a href="mailto:EUprivacy@tevaEU.com">EUprivacy@tevaEU.com</a>, если
                    Вы живете в Германии, то, пожалуйста, свяжитесь с нами по адресу <a href="mailto:datenschutz@teva.de">datenschutz@teva.de</a>. Жители
                    других регионов могут связаться с нами по адресу <a href="mailto:IL_Privacy.Tevail@teva.co.il">IL_Privacy.Tevail@teva.co.il</a>.
                  </p>
                  <p>
                    Надеемся, что мы можем удовлетворить любые запросы, которые у вас могут возникнуть относительно
                    наших способов обработки ваших Персональных данных. Если у вас имеются какие-либо замечания
                    относительно того, как мы обрабатываем ваши Персональные данные, вы можете связаться с отделом
                    защиты данных Тева, обратившись к нам. Если вы живете в Европе, то вы можете связаться с нами по
                    адресу <a href="mailto:EUprivacy@tevaEU.com">EUprivacy@tevaEU.com</a>. Для лиц, проживающих в
                    Европе и Казахстане: если у вас есть
                    вопросы, требующие разрешения, вы также имеете право подать жалобу в уполномоченный орган по
                    месту своего проживания, работы, или по месту, в котором, по вашему мнению, произошло нарушение
                    ваших прав в части защиты Персональных данных.
                  </p>
                  <p>
                    Международная передача данных
                  </p>
                  <p>
                    Тева осуществляет свою деятельность на международном уровне и поэтому ваши Персональные данные
                    могут быть переданы за пределы вашей страны в другие государства, в том числе не входящие в
                    Европейскую экономическую зону, включая Израиль и США. При передаче ваших Персональных данных в
                    Израиль осуществляется на основании решения Европейской комиссии о достаточности мер для
                    государства Израиль. Передача Персональных данных в США осуществляется на основании
                    сертификации, полученной Тева в рамках программы по защите конфиденциальных данных в США.
                    Передача данных в Индию осуществляется на основании типовых положений Европейской комиссии.
                    Информация о соответствующем механизме, используемом компанией Teva, может быть предоставлена по
                    запросу по адресу <a href="mailto:EUprivacy@tevaEU.com">EUprivacy@tevaEU.com</a>.
                  </p>
                  <p>
                    Изменения и дополнения в настоящее Уведомление
                  </p>
                  <p>
                    Если мы решим внести какие-либо изменения в настоящее Уведомление, то мы опубликуем информацию о
                    таких изменениях на Сайте с помощью визуально выделяющегося уведомления. Тем не менее,
                    регулярный просмотр этой страницы позволит вам постоянно знать, какую информацию мы собираем,
                    как Тева использует собранную информацию и при каких условиях будет делиться ею с другими
                    сторонами.
                  </p>
                  <p>
                    Дата вступления в силу: Октябрь 2019 г.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

</template>

<script>
export default {
  name: 'privacy',
  title: 'Уведомление о конфиденциальности и cookie-файлах'
}
</script>
